import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import "../styles/cookies_info.scss";
import { dataLayerPush } from "../utils";
import Button from "./Button";

import cookie1Image from "../img/cookie1.svg";
import cookie2Image from "../img/cookie2.svg";
import cookie3Image from "../img/cookie3.svg";

const getCookie = (name) => {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

const setCookie = (name, value) => {
  var currentDate = new Date();

  var expirationDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
  var expires = expirationDate.toUTCString();

  document.cookie = `${name}=${value}; expires=${expires}`;
};

const Switch = ({
  state = false,
  onChange,
}: {
  state: boolean;
  onChange: Function;
}) => {
  const toggle = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();

    onChange(!state);
  };
  return (
    <label className="switch" onClick={toggle}>
      <input type="checkbox" checked={state} />
      <span className="slider round"></span>
    </label>
  );
};
const CookiesInfo = () => {
  const [cookieAgreed, setCookieAgreed] = useState<boolean>(true);
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [ad_storage, setAdStorage] = useState<boolean>(false);
  const [analytics_storage, setAnalyticsStorage] = useState<boolean>(false);
  const [className, setClassName] = useState<string>("cookies_info_wrapper");

  useEffect(() => {
    setCookieAgreed(
      getCookie("ad_storage") !== null ||
        getCookie("analytics_storage") !== null
    );
  }, []);

  const onFinalizeChoice = () => {
    setClassName("cookies_info_wrapper animate");

    setTimeout(() => dataLayerPush({ event: "consentChoice" }), 500);
    setTimeout(() => dataLayerPush({ event: "consentUpdated" }), 800);

    setCookieAgreed(true);
  };

  const onAgreedClick = () => {
    setAdStorage(true);
    setAnalyticsStorage(true);

    setCookie("ad_storage", "granted");
    setCookie("analytics_storage", "granted");

    onFinalizeChoice();
  };

  const onPartialAgreedClick = () => {
    setCookie("ad_storage", ad_storage ? "granted" : "denied");
    setCookie("analytics_storage", analytics_storage ? "granted" : "denied");

    onFinalizeChoice();
  };

  return !cookieAgreed ? (
    <div className={className}>
      <div className="cookie_backdrop"></div>
      <div className="cookie_modal_wrapper">
        {showAdvanced ? (
          <div className="cookie_modal_wrapper_container">
            <div className="cookie_modal_header">Ustawienia zaawansowane</div>

            <div className="cookie_modal_content_wrapper">
              <p>
                Tutaj możesz sprawdzić, w jaki sposób korzystamy z plików
                cookies. Zaakceptuj wszystkie, aby korzystać z serwisu
                ZnanyLekarz w najbardziej optymalny sposób. Możesz jednak
                wyłączyć niektóre cookies.
              </p>
              <p>
                <Link to="/polityka" className="cookie_agree">
                  Zobacz naszą politykę cookies.
                </Link>
              </p>

              <p>
                <strong>Zarządzaj ustawieniami cookies</strong>
              </p>

              <div className="cookie_type_wrapper">
                <div className="cookie_type_header">
                  <p className="cookie_type_name">Niezbędne</p>
                  <p className="cookie_type_state">Zawsze włączone</p>
                </div>
                <div>
                  Mechanizmy o charakterze niezbędnym są wymagane do
                  prawidłowego działania naszego serwisu. Bez nich część
                  przygotowanych dla Ciebie funkcjonalności nie będzie działać
                  poprawnie lub nie będzie działać wcale. Te mechanizmy są
                  konieczne do funkcjonowania naszego serwisu, dlatego są zawsze
                  aktywne (nie możesz ich wyłączyć).
                </div>
              </div>

              <div className="cookie_type_wrapper">
                <div className="cookie_type_header">
                  <p className="cookie_type_name">Analityczne i statystyczne</p>
                  <p className="cookie_type_state">
                    <Switch
                      state={analytics_storage}
                      onChange={(state) => {
                        setAnalyticsStorage(state);
                      }}
                    />
                  </p>
                </div>

                <div>
                  Wykorzystujemy je do obserwowania, jak nasi użytkownicy
                  korzystają z naszych usług oraz do gromadzenia statystyk.
                  Mechanizmy analityczne pomagają nam zrozumieć w jaki sposób
                  użytkownicy poruszają się po naszym serwisie, a także które
                  strony serwisu cieszą się największą popularnością. Dzięki tym
                  mechanizmom jesteśmy w stanie lepiej przygotowywać nowe
                  funkcjonalności naszego serwisu oraz optymalizować działanie
                  już istniejących. Informacje zawarte w przedmiotowych
                  mechanizmach mogą być przetwarzane także przez naszych
                  partnerów (np. Google). Możesz wyłączyć te pliki cookies.
                  Więcej informacji o wykorzystywanych przez nas narzędziach
                  zewnętrznych znajdziesz w naszej Polityce Prywatności.
                </div>
              </div>

              <div className="cookie_type_wrapper">
                <div className="cookie_type_header">
                  <p className="cookie_type_name">Marketingowe</p>
                  <p className="cookie_type_state">
                    <Switch
                      state={ad_storage}
                      onChange={setAdStorage.bind(null)}
                    />
                  </p>
                </div>
                <div>
                  Mechanizmy reklamowe są wykorzystywane przez nas oraz naszych
                  partnerów do budowania kontentu reklamowego w naszym serwisie.
                  Korzystamy z tych plików we współpracy z naszymi partnerami
                  marketingowymi, np. takimi jak Facebook czy Google. Możesz
                  wyłączyć te pliki cookies. Więcej informacji o
                  wykorzystywanych przez nas narzędziach zewnętrznych znajdziesz
                  w naszej Polityce Prywatności.
                </div>
              </div>

              <p className="cookie_partial_agree_wrapper">
                <span
                  className="cookie_partial_agree"
                  onClick={onPartialAgreedClick}
                >
                  Zapisz moje ustawienia i przejdź do serwisu
                </span>
              </p>
            </div>
            <div className="cookie_modal_footer">
              <Button
                size="S"
                type="secondary"
                onClick={() => setShowAdvanced(false)}
              >
                <span>Powrót</span>
              </Button>
              <Button size="S" onClick={onAgreedClick}>
                <span>Zaakceptuj wszystko</span>
              </Button>
            </div>
          </div>
        ) : (
          <div className="cookie_modal_wrapper_container">
            <div className="cookie_modal_header">
              TwojeZnamiona korzystają z 🍪 🍪 ciasteczek 🍪 🍪
            </div>

            <div className="cookie_modal_content_wrapper">
              <div className="cookie_svg_wrapper">
                <img src={cookie2Image} alt="Ciasteczka" title="Ciasteczka" />

                <img src={cookie1Image} alt="Ciasteczka" title="Ciasteczka" />
                <img src={cookie3Image} alt="Ciasteczka" title="Ciasteczka" />
              </div>
              <strong>Klikając "zaakceptuj"</strong>, pozwalasz nam w pełni
              dostosować serwis TwojeZnamiona do Twoich preferencji . Korzystamy
              z plików cookies głównie po to, aby zwiększyć użyteczność naszego
              serwisu, zapewnić bezpieczeństwo na stronie oraz zbierać dane
              statystyczne. <span className="cookie_modal_content_fordesktop">Pamiętaj, że wyrażenie zgody jest dobrowolne, a
              wyrażoną zgodę możesz w każdej chwili cofnąć, możesz też wycofać
              zgodę na przetwarzanie Twoich danych tylko w niektórych celach.
              Jeżeli chcesz dowiedzieć się więcej lub chcesz przeprowadzić
              konfigurację szczegółową, to możesz tego dokonać za pomocą
              "Ustawień zaawansowanych". Zawsze możesz zmienić zdanie i edytować
              ustawienia cookies.</span> Jeżeli chcesz uzyskać więcej informacji
              odnośnie cookies i przetwarzania danych osobowych, zapoznaj się z
              naszą{" "}
              <Link to="/polityka" className="cookie_agree">
                polityką cookies i polityką przetwarzania danych osobowych.
              </Link>
            </div>
            <div className="cookie_modal_footer">
              <Button
                size="S"
                type="secondary"
                onClick={() => setShowAdvanced(true)}
              >
                <span>Ustawienia zaawansowane</span>
              </Button>
              <Button size="S" onClick={onAgreedClick}>
                <span>Zaakceptuj</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default CookiesInfo;
